import React, { useEffect } from 'react';
import Layout from "../components/public/layout"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faChevronRight, faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import tempGravePhoto from '../images/grave-without-image.svg'
import SearchBar from "../components/public/searchBar"
import { Link } from 'gatsby';
// import { MapContainer, TileLayer } from 'react-leaflet'

const AllGraves = (props) => {
    var id = -1
    if (typeof window !== `undefined`) {
        id = window.location.pathname.split('/')[3]
    }
    var graves = props.pageContext.sectionData.graves
    var section = props.pageContext.sectionData
    const formatDate = (date) => {
        let tempDate = new Date(date)
        if (tempDate.getDate() === 1 && tempDate.getMonth() === 0)
            return tempDate.toLocaleDateString('pl-PL', { year: 'numeric' })
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }
    const handleClick = (id) => {
        if (typeof window !== `undefined`) {
            window.location.pathname = `/cmentarz/kwatera/${id}`
        }
    }
    const checkDate = (date) => {
        let tempDate = new Date(date)
        let treshDate = new Date(2022, 0, 1)
        return tempDate >= treshDate
    }
    useEffect(() => {
        const handleScroll = e => {
            e.preventDefault()
            let up = document.querySelector('#up-button')
            if (window.scrollY > 100)
                up.style.opacity = 1
            else
                up.style.opacity = 0
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <Layout>
            <Helmet title={`Lista grobów | Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="to-up">
                    <button id="up-button" onClick={(() => window.scrollTo(0, 0))}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </button>
                </div>
                <div className="left-col">
                    <span className='where' style={{ padding: 10 }}>
                        <Link to='/'>Strona Główna</Link>
                        <span className='where-arrow'>&gt;</span>
                        <Link to='/cmentarz'>Cmentarz</Link>
                    </span>
                    <h3 style={{ textAlign: 'center', fontSize: 40, marginBottom: '0.5em' }}>Kwatera {section.name}</h3>
                    <div className='all-graves'>
                        <div style={{ display: 'flex', gap: 20, padding: '20px 0', alignItems: 'center', justifyContent: 'center' }}>
                            {
                                id > 1 ?
                                    <button onClick={() => handleClick(parseInt(id) - 1)}>
                                        <FontAwesomeIcon icon={faChevronLeft} /> Kwatera {props.pageContext.prevName}
                                    </button>
                                    : null
                            }
                            {
                                id >= 1 && id < props.pageContext.lastID ?
                                    <button onClick={() => handleClick(parseInt(id) + 1)}>
                                        Kwatera {props.pageContext.nextName} <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                    : null
                            }

                        </div>
                        {
                            graves === null ? typeof window !== undefined ? window.location.reload(true) : null :
                                graves.filter(grave => grave.images.length > 0 || (grave.people.length > 0 && checkDate(grave.people[0].deathDate))).map(grave => {
                                    return (
                                        <Link to={`/cmentarz/grob/${grave.id}`}>
                                            <div className="grave" style={{ display: 'flex ' }}>
                                                {
                                                    grave.images.length === 0 ?
                                                        <img src={tempGravePhoto} alt='' width='35%' height='480px' /> :
                                                        <img src={`${process.env.GATSBY_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[0].name}`} alt='' width='35%' height='480px' />
                                                }
                                                <div className='people' style={{ flex: '60%' }}>
                                                    <h3>
                                                        <strong>{grave.sectionName}</strong><br />
                                                        Rząd: <strong>{grave.row}</strong> Pozycja: <strong>{grave.position}</strong>
                                                        <br />
                                                        <br />
                                                        <span className='cross' style={{ fontSize: 80 }}>
                                                            <strong><FontAwesomeIcon icon={faCross} /></strong>
                                                        </span>
                                                        <br /><br />
                                                        <strong className='s-p' style={{ fontSize: 32 }}>Ś.P.</strong>
                                                    </h3>
                                                    {
                                                        grave.people.map(person => {
                                                            return (
                                                                <div className="person">
                                                                    <strong>{person.firstName} {person.lastName}</strong><br />
                                                                    zm. {person.deathDate === null ? <>b/d</> : `${formatDate(person.deathDate)} r.`}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                        }
                    </div>
                </div>
                <div className="right-col">
                    <SearchBar />
                </div>
            </div>
        </Layout>
    )
}

export default AllGraves;
